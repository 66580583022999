$pad: 15px;
$mar: 15px;
$blue: #6294be;
$grey: #707070;
$greylight: #dfdfdf;
$green: #3bac41;

$font:'Open Sans', sans-serif;

/* Responsive Stuff */
.img-responsive, .thumbnail > img, .thumbnail a > img, .carousel-inner > .item > img, .carousel-inner > .item > a > img {
  width: 100%;
}
/* COL LG - ANYTHING ABOVE 992px */
@media (min-width: 992px) {
  .index-link-mobile {
    display: none;
  }
  .blog-mobile-box {
    display: none;
  }
}
/* COL MD - FROM 768px UP TO 991px */
@media (min-width: 768px) and (max-width: 880px) {
  .hidden-md-sm {
    display: none;
  }
  .blog-mobile-box {
    display: none;
  }
  .navbar-nav .nav-link {
    font-size: 14px !important;
    padding:$pad/2 !important;
  }


  .portrait {
    width: 140px;
    height: 140px;
  }
  .blog-mobile-box {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .container {
    width: 100%;
  }
  .article-gallery .slick-arrow {
    display: none !important;
  }
  .article-gallery {
    margin-top: $mar;
  }
  .index-link-mobile {
    display: none;
  }
  .search-mobile {
    position: relative;
    left: -6px;
  }
  .blog-mobile-box {
    display: none;
  }
  .hidden-ipad {
    display: none;
  }
  .col-ipad-6 {
    width:50%;
  }
  .form-inline .form-control {
    width:100% !important;
  }
}
/* COL SM - FROM 576px UP TO 767px */
@media (min-width: 576px) and (max-width: 767px) {
  body, html{
    overflow-x: hidden;
  }
  .side-search h4 {
    border: none !important;
  }

  .mar-sm {
    margin:$mar/2 !important;
  }
  .product-options {
    margin-bottom:$mar*2;
  }
  .container {
    width: 100%;
  }
  .pad-right, .pad-left, .pad {
    padding: 0;
  }
  .mar-right, .mar-left, .mar-top, .mar-bot {
    margin: 0;
  }
  .container {
    padding: 0 !important;
  }
  .pad-sides-mobile {
    padding: 0 $pad !important;
  }
  .mar-top-mobile {
    margin-top: $mar !important;
  }
  .header-text {
    margin-top: $mar;
  }
  .image-header {
    padding-left: $pad;
    padding-right: $pad;
  }
  /* HEADER */
  header {
    padding: $pad;
  }
  header .navbar {
    height: auto;
    padding: $pad;
    width:100%;
    float: none;

  }
  small {
    font-size: 100% !important;
    font-weight: normal !important;
  }
  header.small .navbar {
    height: auto !important;
  }
  .mobile-logo {
    width: auto;
    margin:0 auto !important;
    padding-top:40px;
    text-align: center;
    display: block;
  }
  .navbar-toggler {
    background-image: none !important;
    color: #FFFFFF !important;
    background-color: transparent !important;
    border-color: transparent !important;
    transition: all 1s;
    border-radius: 0;
    position: relative;
    right:10px;
  }
  .navbar-toggler:hover {
    color: #000000 !important;
  }
  .navbar-toggleable-sm .navbar-nav .nav-item {
    text-align: center;
  }
  .navbar-toggler:after {
    content: '';
    clear: both;
  }
  .navbar-nav .nav-link {
    font-size: 18px !important;
  }
  /* FOOTER */
  .footer-menu {
    display: block;
  }
  .footer-menu li {
    width: 100% !important;
  }
  .footer-menu li a {
    margin: 0 !important;
    float: none !important;
  }
  .app-menu {
    display: block;
  }
  .app-menu li {
    width: 100% !important;
  }
  .app-menu li a {
    margin: 0 !important;
    float: none !important;
  }
  /* CONTACT */
  .contact-page {
    text-align: center;
  }

  /* ABOUT */
  .about-page {
    text-align: center;
  }
  /* HOME PAGE */
  .slick-home-slider .slide-right {
    display: none !important;
  }
  .slick-home-slider .slide-left {
    display: none !important;
  }
  .index-box {
    margin: $mar 0 0 0;
  }
  .blog-page {
    margin-top: $mar;
  }
  .article-gallery .slick-arrow {
    display: none !important;
  }
  .article-gallery {
    margin-top: $mar;
  }
  .index-link {
    display: none;
  }
  .index-link-mobile {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    color: #FFFFFF;
    text-transform: uppercase;
    background-color: rgba(0,0,0,0.7);
    padding: $pad 0;
    font-weight: bold;
  }
  .blog-card-box {
    display: none;
  }
  .mobile-overlay {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    color: #FFFFFF;
    font-weight: bold;
    padding: $pad;
    background-color: rgba(0,0,0,0.7);
    text-transform: uppercase;
  }
  .blog-mobile-box {
    margin-bottom: $mar;
    height: 250px;
    overflow: hidden;
    position: relative;
    margin-right: $mar;
  }
  .blog-mobile-box img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
  }
  .fact-box-1, .fact-box-2, .fact-box-3 {
    margin:$mar 0 0 0;
  }
  .index-box {
    margin: $mar 0 0 0;
  }
  .small-pad-right, .small-pad-left {
    padding:0 !important;
  }
}
/* COL XS - ANYTHING UP TO 575px */
@media (max-width: 575px) {
  .mar-xs {
    margin:$mar !important;
  }
  body, html{
    overflow-x: hidden;
  }
  .pad-right, .pad-left, .pad {
    padding: 0;
  }
  .mar-right, .mar-left, .mar-top, .mar-bot {
    margin: 0;
  }
  .container {
    padding: 0 !important;
  }
  .pad-sides-mobile {
    padding: 0 $pad !important;
  }
  .mar-top-mobile {
    margin-top: $mar !important;
  }
  .header-text {
    margin-top: $mar;
  }
  .image-header {
    padding-left: $pad;
    padding-right: $pad;
  }
  .small-pad-right, .small-pad-left {
    padding:0 !important;
  }
  .fact-box-1, .fact-box-2, .fact-box-3 {
    margin:$mar 0 0 0;
  }
  /* HEADER */
  header {
    padding: $pad;
  }
  header .navbar {
    height: auto;
    padding: $pad;
    width:100%;
    float: none;

  }
  .mobile-logo {
    width: auto;
    margin:0 auto !important;
    padding-top:40px;
    text-align: center;
    display: block;
  }
  small {
    font-size: 100% !important;
    font-weight: normal !important;
  }
  header.small .navbar {
    height: auto !important;
  }

  .navbar-toggler {
    background-image: none !important;
    color: #FFFFFF !important;
    background-color: transparent !important;
    border-color: transparent !important;
    transition: all 1s;
    border-radius: 0;
    float: right;
  }
  .navbar-toggler:hover {
    color: #000000 !important;
  }
  .navbar-toggleable-sm .navbar-nav .nav-item {
    text-align: center;
  }
  /* FOOTER */
  .border-left {
    border:none !important;
    h4 {
      margin-top:$mar;
    }
  }
  /* CONTACT */
  .contact-page {
    text-align: center;
  }
  /* Shop */
  .side-search h4 {
    border: none !important;
  }
  .products-tabs .nav-tabs li a {
    padding:$mar/2 !important;
    font-size: 14px;
  }
  /* ABOUT */
  .about-page {
    text-align: center;
  }
  /* HOME PAGE */
  .slick-home-slider .slide-right {
    display: none !important;
  }
  .slick-home-slider .slide-left {
    display: none !important;
  }
  .index-box {
    margin: $mar 0 0 0;
  }
  .blog-page {
    margin-top: $mar;
  }
  .article-gallery .slick-arrow {
    display: none !important;
  }
  .article-gallery {
    margin-top: $mar;
  }
  .index-link {
    display: none;
  }
  .index-link-mobile {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    color: #FFFFFF;
    text-transform: uppercase;
    background-color: rgba(0,0,0,0.7);
    padding: $pad 0;
    font-weight: bold;
  }
  .article-title {
    font-size: 1.5rem;
  }
  .blog-card-box {
    display: none;
  }
  .mobile-overlay {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    color: #FFFFFF;
    font-weight: bold;
    padding: $pad;
    background-color: rgba(0,0,0,0.7);
    text-transform: uppercase;
  }
  .blog-mobile-box {
    margin-bottom: $mar;
    height: 250px;
    overflow: hidden;
    position: relative;
  }
  .blog-mobile-box img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
  }
}